let getDictionaryLIst = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取行业遵从搜索列表数据
     * @param {any} success
     * @param {any} error
     */
    this.getDict = function (success, error) {
        var url = ServiceBaseAddress + '/api/Dict/'
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    
    this.getDictKeywordSearch = function ( e  ,success, error) {
        var url = ServiceBaseAddress + '/api/Dict/' + e
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
        
    this.getDictKeywordSearch1 = function ( dictId , categoryId ,keyword ,firstSpell ,firstChar ,orderIndex ,success, error) {
        var url = ServiceBaseAddress + '/api/Dict/' + dictId + '/' + categoryId + '/' + keyword+ '/' + firstSpell+ '/' + firstChar + '/' + orderIndex
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getDictMenu = function (success, error) {
        var url = ServiceBaseAddress + '/api/Dict/Menu/'
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getCategories = function (success, error) {
        var url = ServiceBaseAddress + '/api/Dict/Categories/'
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getDictKeyword = function (keywordId ,success, error) {
        var url = ServiceBaseAddress + '/api/Dict/Keyword/' + keywordId 
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { getDictionaryLIst }